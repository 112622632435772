/**
* Generated automatically at built-time (2025-01-31T08:35:25.587Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "kopguide",templateKey: "sites/99-5d12dc74-92f5-4985-a46c-ecf36c3654c0"};